import './contacto.css';
import Slider from "../../components/slider/slider";
import Footer from "../../components/footer/footer";
import ContactoForm from "../../components/contactoform/contactoform";

const Contacto = () => {
    window.scrollTo(0, 0);
    return (
        <section className='contenedor-index'>

            <Slider background="/img/doctors.jpg">
                <div className="banner" style={{ padding: "0.5rem 1rem", marginBottom: "-2rem"}}>
                    <div style={{ "display": "grid", "grid-template-columns": "1fr 6fr" }}>
                        <div style={{ "display": "flex" }}><img src="img/location.svg" width="80%" /></div>
                        <div style={{ "display": "flex", "align-items": "center" }}><p>Calle 7 # 14 - 18 Neiva, Huila</p></div>
                    </div>
                    <div style={{ "display": "grid", "grid-template-columns": "1fr 4fr" }}>
                        <div style={{ "display": "flex" }}><img src="img/atencion-al-cliente.svg" width="80%" /></div>
                        <div style={{ "display": "flex", "align-items": "center" }}><p>Tel. (57) 8 8711473  - (57) 8 720514
                            Cel.(57) 317 3671647</p></div>
                    </div>
                    <div style={{ "display": "grid", "grid-template-columns": "2fr 4fr", paddingleft: "2rem" }}>
                        <div style={{ "display": "flex", "align-items": "center", justifyContent: "space-around" }}>
                            <img src="img/carta.svg" width="50%" />
                        </div>
                        <div style={{ "display": "flex", "align-items": "center", justifyContent: "flex-start"}}>
                            <p>Contacto@farmedicall.com</p>
                        </div>
                    </div>
                </div>
            </Slider>
            <div className='contacto-body'>
                <h3>Déjanos tus datos y nos comunicaremos lo más pronto
                    posible.
                </h3>
                <div style={{ "width": "50%", "margin": "auto" }}>
                    <ContactoForm></ContactoForm>
                </div>
            </div>
            <section className='horario'>
                <div
                    style={{
                        margin: "auto",
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-end",
                    }}>
                    <div className="horario-mapa"
                        style={{

                        }}>
                        <img src="img/mapaHuila.png" style={{ width: "70%", margin: "auto" }} />
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                    <div className="horario-card">
                        <h2>Horarios de atención</h2>
                        <p>
                            <span style={{ color: "var(--secondary-color)" }}>Lunes - Viernes:</span>  7:00 a.m - 12:00 p. m  01:00 p.m - 05:00pm
                        </p>
                        <p><span style={{ color: "var(--secondary-color)" }}>Sabados:</span> 8:00 a.m - 12:00 p. m</p>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </section>
    )
}

export default Contacto;