import Slider from '../../components/slider/slider'
import Nuestrosservicios from "../../components/nuestrosservicios/nuestrosservicios";

import './index.css'
import ProductosDestacados from "../../components/productosdestacados/productosdestacados";
import Marcalaboratorio from "../../components/marcalaboratorio/marcalaboratorio"
import Contactanos from "../../components/contactanos/contactanos"
import Footer from '../../components/footer/footer';

function Index() {
    window.scrollTo(0, 0);
    return (
        <>
            <section className='contenedor-index'>
                <Slider background="/img/young-woman.jpg">
                    <div class="banner" style={{ gridTemplateColumns: "1fr 3fr 1fr", backgroundColor: "rgb(32, 64, 142, 0.65)" }} >
                        <div style={{
                            backgroundColor: "var(--secondary-color)",
                            borderRadius: "20px 0 0 20px",
                            padding: "0.5rem 2rem",
                            fontWeight: "bold",
                        }}>
                            <div style={{ "display": "flex", "align-items": "center", textAlign: "left" }}>
                                <p>Nuetro amplio portafolio</p>
                            </div>
                        </div>
                        <div style={{
                            display: "grid", "grid-template-columns": "repeat(5, 1fr)",
                            justifyItems: "center",
                            padding: "1rem",
                            backgroundColor: "var(--main-color)",
                        }}>
                            <img src="img/medicina-natural.svg" width="50%" />
                            <img src="img/medicina.svg" width="50%" />
                            <img src="img/medicinas1.svg" width="50%" />
                            <img src="img/pildora.svg" width="50%" />
                            <img src="img/medicamentos-intravenosos.svg" width="50%" />
                        </div>
                        <div style={{ "display": "flex", "align-items": "center", justifyContent: "space-around" }}>
                            <div style={{
                                padding: "0.5rem 1rem",
                                backgroundColor: "rgba(255, 255, 255)",
                                color: "var(--secondary-color)",
                                fontWeight: "bold",
                                borderRadius: "10px"
                            }}>
                                ¡Conócelo aquí!
                            </div>
                        </div>
                    </div>
                </Slider>
                <Nuestrosservicios></Nuestrosservicios>
                <ProductosDestacados></ProductosDestacados>
                <Marcalaboratorio></Marcalaboratorio>
                <Contactanos></Contactanos>
                <Footer></Footer>
            </section>
        </>
    );
}

export default Index