import './acordeon.css';
import {useState} from "react";

const Acordeon = (props) => {
    const {children} = props;
    const [active, setActive] = useState(false);

    return (
        <div className="acordeon">
            <div className="acordeon-header">
                <div style={{margin: "auto 0"}}>Medicamentos</div>
                <div className={`toggle ${active ? "toggle-active" : ""}`}>
                    <a onClick={() => setActive(!active)}></a>
                </div>
            </div>
            <div className={`acordeon-body ${active ? "active" : ""}`}>
                {children}
            </div>
        </div>
    );
}


export default Acordeon;