import Footer from "../../components/footer/footer";
import Slider from "../../components/slider/slider";
import Contactanos from "../../components/contactanos/contactanos";
import NuestraLineaServicios from "../../components/nuestralineaservicios/nuestralineaservicios"
import Cobertura from "../../components/cobertura/cobertura";
import './servicios.css'

const Servicios = () => {
    window.scrollTo(0, 0);
    return (
        <section className='contenedor-index'>
            <Slider background="/img/pharmasict.jpg">
                <div className="banner banner-servicios"  style={{ gridTemplateColumns: "1fr 1fr 1fr", backgroundColor: "rgb(32, 64, 142, 0.65)", width: "65%" }} >
                    <div style={{
                        backgroundColor: "var(--main-color)",
                        borderRadius: "20px 0 0 20px",
                        padding: "1rem",
                        fontWeight: "bold",
                    }}>
                        <div style={{ "display": "flex", "align-items": "center", justifyContent: "space-around" }}>
                            <p>Dispensación</p>
                        </div>
                    </div>
                    <div style={{ "display": "flex", "align-items": "center", justifyContent: "space-around" }}>
                        <div style={{
                            padding: "0.5rem 1rem",
                            fontWeight: "bold",
                            borderRadius: "10px",
                        }}>
                            <p>Comercialización y distribución</p>
                        </div>
                    </div>
                    <div style={{ "display": "flex", "align-items": "center", justifyContent: "space-around" }}>
                        <div style={{
                            padding: "0.5rem 1rem",
                            fontWeight: "bold",
                            borderRadius: "10px"
                        }}>
                          <p>Operación logística</p>
                        </div>
                    </div>
                </div>
            </Slider>
            <NuestraLineaServicios></NuestraLineaServicios>
            <Cobertura></Cobertura>
            <Contactanos></Contactanos>
            <Footer></Footer>
        </section>
    )
}

export default Servicios;