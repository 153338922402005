import './productosdestacados.css'
import {useEffect, useState} from "react";
import {fetchProductosDestacadosResource} from "../../util/api";

function ProductosDestacados() {

    const [seccionProductosDestacados, setSeccionProductosDestacados] = useState({
        titulo: '',
        subtitulo: '',
        productos: []
    });

    useEffect(() => {
        fetchProductosDestacadosResource()
            .then(seccion => {
                setSeccionProductosDestacados(seccion);
            })
    }, []);

    return (
        <section className="productosdestacados">
            <div className="productosdestacados-title">
                <h1>{seccionProductosDestacados.titulo}</h1>
                <p style={{width: "70%", margin: "auto auto 2rem auto"}}>
                    {seccionProductosDestacados.subtitulo}
                </p>
            </div>
            <div className="cards">
                {seccionProductosDestacados.productos.map(producto => {
                    return <Card titulo={producto.titulo}
                                 img={producto.img}
                                 texto={producto.texto}
                                 footer={producto.footer}/>
                })}
            </div>
        </section>
    )
}


const Card = (props) => {
    const {titulo, img, texto, footer} = props;
    return (
        <>
            <div className="card card-hover shadow ">
                <div className="card-header">
                    <strong>{titulo}</strong>
                </div>
                <div className="card-body">
                    <img src={img} alt='young' style={{width: "100%", height: "auto"}}/>
                </div>
                <div className="card-footer">
                    <p className="bottom-line" style={{color: "#818181"}}>{texto}</p>
                    <strong>{footer}</strong>
                </div>
            </div>
        </>
    );
}
export default ProductosDestacados