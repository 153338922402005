import './nuestrosservicios.css'
import {useEffect, useState} from "react";
import {fetchNuestrosServiciosResource} from "../../util/api";

const Nuestrosservicios = () => {

    const [seccionNuestrosServicios, setSeccionNuestrosServicios] = useState({
        titulo: '',
        subtitulo: '',
        servicios: []
    });


    useEffect(() => {
        fetchNuestrosServiciosResource()
            .then(nuestrosServicios => setSeccionNuestrosServicios(nuestrosServicios))
    }, []);

    return (
        <section className="nuestrosservicios">
            <div className="nuestrosservicios-title">
                <h1>{seccionNuestrosServicios.titulo}</h1>
                <p>{seccionNuestrosServicios.subtitulo}</p>
            </div>
            <div className="cards">
                {seccionNuestrosServicios.servicios.map(servicio => {
                    return <Card titulo={servicio.titulo} img={servicio.img} footer={servicio.footer}/>
                })}
            </div>
        </section>
    );
}

const Card = (props) => {
    const {titulo, img, footer} = props;
    return (
        <>
            <div className="card card-hover shadow ">
                <div className="card-header">
                    {titulo}
                </div>
                <div className="card-body bottom-line-1">
                    <img src={img} alt='young' className='responsive'
                         style={{width: '50%', color: "var(--secondary-color)"}}/>
                </div>
                <div className="card-footer">
                    {footer}
                </div>
            </div>
        </>
    );
}

export default Nuestrosservicios;