import Footer from "../../components/footer/footer";
import Slider from "../../components/slider/slider";
import FormulaNueva from "../../components/formulanueva/formulanueva"

const ForlumaMedicaNueva = () => {
    window.scrollTo(0, 0);
    return (
        <section className='contenedor-index'>
            <Slider  background="/img/paperhospital.jpg"></Slider>
            <section style={{ width: "40%", margin: "3rem auto" }}>
                <FormulaNueva></FormulaNueva>
            </section>
            <Footer></Footer>
        </section>
    )
}

export default ForlumaMedicaNueva;