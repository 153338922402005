import './header.css'
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Header() {

    const [navbar, setNavbar] = useState(false)
    const changeBackground = () => {
        setNavbar(window.scrollY >= 50)
    }

    useEffect(() => {
        changeBackground()
        window.addEventListener("scroll", changeBackground)
    })

    return (
        <header className={navbar ? "shadow header-solid" : ""}>
            <div className="logo-img">
                <Link to="/"><img src="/img/logo.png" alt="logo" height="60" /></Link>
            </div>
            <nav>
                <ul>
                    <li><Link to="/nosotros">NOSOTROS</Link></li>
                    <li><Link to="/productos">PRODUCTOS</Link></li>
                    <li><Link to="/servicios">SERVICIOS</Link></li>
                    <li><Link to="/contacto">CONTACTO</Link></li>
                    <li>
                        <Link to="/ingresar">
                            <div className="button"
                                style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                                <img src="/img/user.svg"></img>
                                <span>INGRESAR</span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/radicarorden">
                            <a className="button"
                                style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                                <img src="/img/orden.svg"></img>
                                <span>RADICAR ORDEN</span>
                            </a>
                        </Link>
                    </li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;