import './contactoform.css';

const ContactoForm = () => {
    return (
        <>            
            <div className="contactanos-form">
                <form name="formulario1" className="formulario">
                    <div style={{"display": "grid", "grid-template-columns": "2fr 1fr"}}>
                        <div className="form-group">
                            <label htmlFor="nombre y apellido">NOMBRE Y APELLIDO</label>
                            <input type="text" id="nombre y apellido"
                                    name="nombre y apellido"
                                    className="form-input"
                                    placeholder="*TU NOMBRE COMPLETO" required="true"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="telefono">TELEFONO</label>
                            <input type="text" id="telefono" name="telefono"
                                    className="form-input"
                                    placeholder="*TU TELEFONO"
                                    required="true"/>
                        </div>
                    </div>
                    <div style={{"display": "grid", "grid-template-columns": "1fr 1fr"}}>
                        <div className="form-group">
                            <label htmlFor="email">CORREO ELECTTRONICO</label>
                            <input type="email" id="email"
                                    className="form-input"
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" name="email"
                                    placeholder="*TU CORREO" required="true"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="solicitud">TIPO DE SOLICITUD</label>
                            <select name="solicitud" id="solicitud" className="form-input">
                                <option value="tipo de solicitud">*TIPO DE SOLICITUD</option>
                                <option value="Dispensación">Dispensación</option>
                                <option value="Dispensación">Dispensación</option>
                                <option value="Dispensación">Dispensación</option>
                                <option value="Dispensación">Dispensación</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <textarea name="mensaje" id="mensaje" placeholder="*TU MENSAJE" cols="20"
                                    className="form-input" rows="10">
                        </textarea>
                    </div>
                    <div className="submit">
                        <input type="submit" value="Enviar" className="button"/>
                    </div>
                </form>
            </div>
        </>
    )
}

export default ContactoForm;