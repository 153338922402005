const qs = require('qs');

let strapiUrl = `http://vps-b85bc444.vps.ovh.ca:1337`;
let strapiApi = `${strapiUrl}/`;

const apiId = {
    nuestrosServicios: 'seccion-nuestros-servicios',
    productosDestacados: 'seccion-producto-destacado',
    marcaslaboratorios: 'seccion-marcas-laboratorio'
}

function fetchResource(apiId, query) {
    return fetch(`${strapiApi}${apiId}?${query}`, {
        headers: {
            authorization: 'Bearer 81e55bc79e617aa8599290dc8aad061f623e37e2f2a9b3c1e10e8b94b3f7f2792b92350d151f8b57ef4ac2009cf99636470e4ec57a9dae42c548c008ce04cdbd617ce5b8d848576fa5100fe43aa10da113db58f64b886a232f8caeb7dbcad84e98e98ea799846cd1b906f7c8b6668be0c8b83b6476df8f401c0d8570dedc3068'
        }
    }).then(response => response.json())
}

const mapNuestrosServicios = (seccionData) => {
    let servicios = seccionData.attributes.nuestros_servicios.data.map(s => {
        return {
            titulo: s.attributes.titulo,
            footer: s.attributes.footer,
            img: strapiUrl + s.attributes.img.data.attributes.url,
        }
    })
    return {
        titulo: seccionData.attributes.titulo,
        subtitulo: seccionData.attributes.subtitulo,
        servicios: servicios
    }
}

const mapProductosDestacados = (seccionData) => {
    let productosDestacados = seccionData.attributes.productos_destacados.data.map(p => {
        return {
            titulo: p.attributes.base.titulo,
            footer: p.attributes.base.footer,
            texto: p.attributes.texto,
            img: strapiUrl + p.attributes.base.img.data.attributes.url,
        }
    })
    return {
        titulo: seccionData.attributes.header.titulo,
        subtitulo: seccionData.attributes.header.subtitulo,
        productos: productosDestacados
    }
}

const mapMarcasLaboratorios = (seccionData) => {
    const marcas = seccionData.attributes.marca_laboratorios.data.map(m => {
        return {
            alt: m.attributes.alt,
            img: strapiUrl + m.attributes.logo.data.attributes.url
        }
    })
    return {
        titulo: seccionData.attributes.section.titulo,
        subtitulo: seccionData.attributes.section.subtitulo,
        marcas: marcas
    }
}

export const fetchNuestrosServiciosResource = () => {
    const query = qs.stringify({
        populate: {
            nuestros_servicios: {
                populate: ['img'],
            }
        }
    }, {
        encodeValuesOnly: true, // prettify URL
    });

    return fetchResource(apiId.nuestrosServicios, query)
        .then(response => mapNuestrosServicios(response.data))
}

export const fetchProductosDestacadosResource = () => {
    const query = qs.stringify({
        populate: {
            header: {
                populate: '*'
            },
            productos_destacados: {
                populate: {
                    base: {
                        populate: '*'
                    }
                }
            }
        }
    }, {
        encodeValuesOnly: true
    });

    return fetchResource(apiId.productosDestacados, query)
        .then(response => mapProductosDestacados(response.data))
}

export const fetchMarcasLaboratoriosResource = () => {
    const query = qs.stringify({
        populate: {
            section: '*',
            marca_laboratorios: {
                populate: ['logo']
            }
        }
    }, {
        encodeValuesOnly: true
    });

    return fetchResource(apiId.marcaslaboratorios, query)
        .then(response => mapMarcasLaboratorios(response.data))
}
