import './portafoliodeproductos.css'
import Acordeon from "../acordeon/acordeon";

import React, { useState } from 'react';

import { Document, Page } from 'react-pdf';

const Portafoliodeproductos = () => {

    return (
        <section className="portafoliodeproductos">
            <div className="portafoliodeproductos-title">
                <h1>Conoce nuestro portafolio de productos</h1>
            </div>
            <div>
                <Acordeon>
                    <Medicamentos></Medicamentos>
                </Acordeon>
            </div>
            <div className="accordion">
                <div className="accordion-item">
                    <div className="accordion-item-header">
                        Vitales no disponibles
                    </div>
                </div>
                <div className="accordion-item">
                    <div className="accordion-item-header">
                        Insumos médico quirurgicos
                    </div>
                </div>
                <div className="accordion-item">
                    <div className="accordion-item-header">
                        Suplementos dietarios
                    </div>
                </div>
                <div className="accordion-item">
                    <div className="accordion-item-header">
                        Alimentos
                    </div>
                </div>
                <div className="accordion-item">
                    <div className="accordion-item-header">
                        Productos de aseo y limpieza
                    </div>
                </div>
            </div>
        </section>
    )
}

const Producto = (props) => {
    const {img, texto} = props;
    return (
        <>
            <div className="indicador">
                <div className="indicador-img">
                    <img src={img} alt="Indicador"/>
                </div>
                <div className="indicador-texto">
                    <strong>{texto}</strong>
                </div>
            </div>
        </>
    )
}

const Medicamentos = () => {
    const productos = [
        {
            img: '/img/alimentos.png',
            texto: "Alimentos y metabolismo",
        },
        {
            img: '/img/sangre.png',
            texto: "Sangre y órganos hematopoyéticos",
        },
        {
            img: '/img/sistemas.png',
            texto: "Sistema cardiovascular",
        },
        {
            img: '/img/dermatologico.png',
            texto: "Dermatológicos",
        },
        {
            img: '/img/sistemasge.png',
            texto: "Sistema genitourinario y hormonas sexuales",
        },
        {
            img: '/img/preparadosH.png',
            texto: "Preparados hormonales sistémicos, excluyendo hormonas sexuales e insulinas ",
        },
        {
            img: '/img/antiinfeccioso.png',
            texto: "Antiinfecciosos para uso sistémico",
        },
        {
            img: '/img/antineoplasicos.png',
            texto: "Antineoplásicos e inmunomoduladores",
        },
        {
            img: '/img/musculoesqueletico.png',
            texto: "Sistema musculoesquelético",
        },
        {
            img: '/img/sistemaNervioso.png',
            texto: "Sistema nervioso",
        },
        {
            img: '/img/antiparasitos.png',
            texto: "Antiparasitarios",
        },
        {
            img: '/img/pulmones.png',
            texto: "Sistema respiratorio",
        },
        {
            img: '/img/sentidos.png',
            texto: "Órganos de los sentidos",
        }
    ];


    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    return (<>
            <div style={{"display": "grid", "grid-template-columns": "1fr 1fr"}}>
                <div className="alimentometabolismo">
                    <h1>Metabolismo</h1>
                    <p style={{lineHeight: "1.5rem", fontWeight: "400"}}>Somos importadores exclusivos para
                        Colombia en la línea de hemoderivados del Factor VIII “GREEN VIII”,
                        además de ser distribuidores autorizados en el país de grandes laboratorios
                        farmacéuticos.
                        Nuestro aliado estratégico para el embalaje, transporte y entrega directa de
                        medicamentos con cadena de frío es BIOTHERMICS;
                        empresa Colombiana certficada por el Invima que cuenta con más de 14 años de
                        experiencia en la industria farmacéutica,
                        proporcionando soluciones de embalaje térmico con monitoreo y trazabilidad de la
                        termperatura de los productos transportados, asegurando así,
                        la conservación adecuada según sus especificaciones técnicas.</p> <br></br>
                </div>
                <section className="portafolioproductos-column">
                    <div>
                        <img src="/img/medicine-.png" alt="medicine"/>
                    </div>
                    <div>
                        <img src="/img/medicine2.png" alt="medicine2"/>
                    </div>
                    <div>
                        <img src="/img/medicine3.png" alt="medicine3"/>
                    </div>
                    <div>
                        <img src="/img/medicine4.png" alt="medicine4"/>
                    </div>
                </section>
            </div>
            <div style={
                {
                    "display": 'grid',
                    "grid-template-columns": "repeat(auto-fit, minmax(min(100%, 12rem), 1fr))",
                    "width": "70%",
                    "margin": "auto",
                    "gap": "1rem"
                }
            }>
                {productos.map(p => {
                    return <Producto img={p.img} texto={p.texto}></Producto>
                })}
            </div>

            <section>   <a href="atc.pdf" target="_blank" rel="noopener noreferrer">Medicamentos </a> </section>
        </>
    );
}

export default Portafoliodeproductos;