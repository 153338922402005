import './cobertura.css';

const Cobertura = () => {
    return (
        <section className="cobertura">
            <div style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "5%",
                paddingRight: "30%",
                fontWeight: "bold",

            }}>
                <h1 className="top-line">
                    <p>
                        Tenemos cobertura en todo</p><p> Colombia</p>
                </h1>
            </div>
            <div style={{
                display: "flex",
                justifyContent: "space-around",
                height: "100%"
            }}>
                <div style={{
                    width: "50%",
                    marginTop: "3%",
                    fontSize: "1.2rem",
                }}>
                    <p style={{background: "#fff", padding: "2rem"}}>
                    comercializamos y distribuimos un amplio portafolio de productos farmacéuticos a nivel nacional que cubren las principales necesidades de salud de en el ámbito ambulatorio y hospitalario.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default Cobertura;