import './ingresar.css';
import Footer from "../../components/footer/footer";

function Ingresar() {
    window.scrollTo(0, 0);
    return (
        <section className='contenedor-index' style={{
            background: "url(/img/inventory.jpg) rgba(32, 64, 142, 0.49)",
            backgroundPosition: "top",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundBlendMode: "multiply",
            height: "100vh",
        }}>
            <div style={{ margin: "8rem auto 4rem auto" }}>
                <div className="contenedor">
                    <h2><img src="img/logo.png" height="60" /></h2>
                    <form id="forma" name="forma" method="POST">
                        <div class="form-group">
                            <label for="usuario">Usuario o email</label>
                            <input className="form-input" type="text" id="usurio" name="usuario" required="true" />
                        </div>
                        <div class="form-group">
                            <label for="contraseña">Contraseña</label>
                            <input className="form-input" type="contraseña" id="contraseña" name="contraseña" required="true" />
                            <p class="login-help" style={{ display: 'flex', flexDirection: "row-reverse", fontSize: "14px" }}>
                                <a href="#">¿Olvidó su contraseña?</a></p>
                        </div>
                        <div style={{
                            width: "70%", margin: "auto"
                        }}><input className='button button2' type="submit" value="Enviar" /></div>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default Ingresar;  