import './nuestroproposito.css';
import { Document, Page } from 'react-pdf';


function NuestroProposito() {
    const indicadores   = [
        {
            img: '/img/data-analytics.png',
            texto: "Pacientes satisfechos el ultimo año ",
            titulo: '10.000+'
        },
        {
            img: '/img/data-analytics2.png',
            texto: "Rapidez en entrega de medicamento como mayoristas ",
            titulo: '-48 Horas'
        },
        {
            img: '/img/vector1.png',
            texto: "Puntos de atención",
            titulo: '5+'
        },
        {
            img: '/img/data-analytics3.png',
            texto: "Moleculas ",
            titulo: '2.000+'
        },
        {
            img: '/img/data-analytics4.png',
            texto: "Fabricantes ",
            titulo: '300+'
        },
        {
            img: '/img/data-analytics5.png',
            texto: "Cotizacion en ",
            titulo: '-12 Horas'
        }
    ]

    const politicas   = [
        {   
            img: '/img/1data-analytics.png',
            titulo: <a href="Politica_calidad.pdf" target="_blank" rel="noopener noreferrer"> Política de Calidad </a>
        },
        {
            img: '/img/la-seguridad.png' ,
            titulo: <a href="Politica_SST.pdf" target="_blank" rel="noopener noreferrer">Política De Seguridad Y Salud En El Trabajo</a>
        },
        {
            img: '/img/seguro-de-viaje.png',
            titulo: <a href="Politica_ambiental.pdf" target="_blank" rel="noopener noreferrer">Política Ambiental</a>
        },
        {
            img: '/img/poliza-de-seguros.png',
            titulo: <a href="Politica_devolucion.pdf" target="_blank" rel="noopener noreferrer">Política Devoluciones De Productos Farmacéuticos</a>            
        },
        {
            img: '/img/politico.png',
            titulo: <a href="politica_prevencion.pdf" target="_blank" rel="noopener noreferrer">Política De Prevención De Consumo De Tabaco, Alcohol Y Drogas</a>
        }
    ]

    return (
        <section className="nuestroproposito">
            <div className="nuestroproposito-title">
                <h1>Nuestro Propósito</h1>
                <p>
                    Trabajamos en la búsqueda de desarrollos científicos de alta calidad,
                    para ofrecer las mejores soluciones a sus necesidades
                </p>
            </div>
            <div className="nuestroproposito-title">
                <h1>Misión</h1>
                <p>
                    Trabajamos para suministrar y distribuir a nuestros clientes y usuarios productos farmacéuticos
                    especializados de alta calidad, nacionales e importados, ofreciéndoles asesoría técnica para un manejo
                    adecuado.
                </p>
            </div>
            <div className="nuestroproposito-title">
                <h1>Visión</h1>
                <p>
                    Consolidarnos para el 2024 como una excelente alternativa de productos farmacéuticos especializados de alta
                    calidad y a un precio justo.
                </p>
            </div>
            <div className="nuestroproposito-title">
                <h1>Valores Corporativos</h1>
                <p>
                   Servicio    -   Confianza   -  Unidad   -   Responsabilidad
                </p>
                <br></br>
                <br></br>
                <h1>Indicadores Empresariales</h1>
            </div>
          
            <div className="nuestroproposito-indicadores">
                
                {indicadores.map(indicador => {
                    return <Indicador titulo={indicador.titulo} img={indicador.img} texto={indicador.texto}></Indicador>
                })}
            </div>

            <div className="nuestroproposito-title">
            <h1>POLÍTICAS EMPRESARIALES </h1>
            </div>
            <div className="nuestroproposito-politica">
                {politicas.map(indicador => {
                    return <Indicador titulo={indicador.titulo} img={indicador.img} texto={indicador.texto}></Indicador>
                })}
                
            </div>
        </section>
        
    )

}

const Indicador = (props) => {
    const {titulo, img, texto} = props;
    return (
        <>
            <div className="indicador">
                <div className="indicador-img">
                    <img src={img} alt='young'/>
                </div>
                <div className="indicador-title">
                    {titulo}
                </div>                
                <div className="indicador-texto">
                    {texto}
                </div>
            </div>
        </>
    )
}


export default NuestroProposito;