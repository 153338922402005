import { Link } from 'react-router-dom';
import Slider from "../../components/slider/slider";
import './formulamedica.css';


const FormulaMedica = () => {
    const formulas = [  
        {titulo: "Fórmula médica nueva", link: "formulanueva"},
        {titulo: "Fórmula médica recurrente",},
        {titulo: "No tengo fórmula médica",},
    ];
    return (
            <div 
            style ={{
                width: "60%",
                display: 'grid', 
                margin: "5rem auto",            
                gridTemplateColumns: "repeat(auto-fit, minmax(min(100%, 15rem), 1fr))",
                gap: "2rem",                         
                }}>
                {formulas.map(f => {
                    return <Formula titulo={f.titulo} link={f.link}></Formula>
                })}                                    
            </div>
    )
}

const Formula = (props) => {
    const { titulo, link } = props;
    return (
            <div className="formula">                
                <Link to="/formulamedicanueva">
                    <div className="card-header">
                        <h1>{titulo}</h1>
                    </div>
                </Link>
            </div>            
    );
}


export default FormulaMedica;