import Slider from "../../components/slider/slider";
import Footer from "../../components/footer/footer";
import Portafoliodeproductos from "../../components/portafoliodeproductos/portafoliodeproductos";

function Productos() {
    window.scrollTo(0, 0);
    return (
        <section className='contenedor-index'>
                <Slider background="/img/productoDestacado.png"></Slider>
            <Portafoliodeproductos></Portafoliodeproductos>
            <Footer></Footer>
        </section>
    )
}

export default Productos;