import './formulanueva.css';

const FormulaNueva = () => {

    return (
        <div className="registrate-form">
            <h1 style={{color: "var(--secondary-color)"}}>Regístrate</h1>
            <form name="formulario1" className="formulario">
                <div>
                    <div className="form-group">
                        <label htmlFor="nombres">Nombres</label>
                        <input type="text" id="nombres"
                               name="nombre y apellido"
                               className="form-input"
                               placeholder="Nombres" required="true"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="apellidos">Apellidos</label>
                        <input type="text" id="apellidos"
                               name="apellidos"
                               className="form-input"
                               placeholder="Apellidos" required="true"/>
                    </div>
                    <div style={{display: "grid", gridTemplateColumns: "1fr 2fr"}}>
                        <div className="form-group">
                            <label htmlFor="tipoDocumento">Tipo de documento</label>
                            <select name="tipoDocumento" id="tipoDocumento" className="form-input">
                                <option value="tiposolicitud">Tipo de documento</option>
                                <option value="CC">Cédula de ciudadanía</option>
                                <option value="TI">Tarjeta de identidad</option>
                                <option value="CE">Cédula de extranjería</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="numero">Número</label>
                            <input type="text" id="numero"
                                   name="numero"
                                   className="form-input"
                                   placeholder="Número de identificación" required="true"/>
                        </div>
                    </div>
                    <div style={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
                        <div className="form-group">
                            <label htmlFor="sexo">Sexo</label>
                            <select name="sexo" id="sexo" className="form-input">
                                <option value="Sexo">Sexo</option>
                                <option value="m">Másculino</option>
                                <option value="f">Femenino</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="fechaNacimiento">Fecha de nacimiento</label>
                            <input type="date" id="apellidos"
                                   name="apellidos"
                                   className="form-input"
                                   placeholder="Apellidos" required="true"/>
                        </div>
                    </div>
                    <div>
                        <p style={{color: "black", fontSize: "0.8rem", textAlign: "left", paddingLeft: "1rem"}}>
                            Asegúrate de que coincide con los datos que aparecen en tú cédula
                        </p>
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">CORREO ELECTTRONICO</label>
                        <input type="email" id="email"
                               className="form-input"
                               pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" name="email"
                               placeholder="*TU CORREO" required="true"/>
                    </div>
                    <div style={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
                        <div className="form-group">
                            <label htmlFor="celular">Celular</label>
                            <input type="text" id="celular" name="celular"
                                   className="form-input"
                                   placeholder="Celular"
                                   required="true"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="numeroContacto">Número de contacto (opcional)</label>
                            <input type="text" id="numeroContacto" name="numeroContacto"
                                   className="form-input"
                                   placeholder="Número de contacto"
                                   required="true"/>
                        </div>
                    </div>
                    <div style={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
                        <div className="form-group">
                            <label htmlFor="eps">EPS</label>
                            <select name="eps" id="eps" className="form-input">
                                <option value="comfamiliar">Comfamiliar</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="ciudadMunicipio">Ciudad/Municipio</label>
                            <input type="text" id="ciudadMunicipio" name="ciudadMunicipio"
                                   className="form-input"
                                   placeholder="Ciudad/Municipio"
                                   required="true"/>
                        </div>
                    </div>
                    <div>
                        <div className="form-group">
                            <label>Dirección de residencia</label>
                            <input type="text" id="direccion" name="direccion"
                                   className="form-input"
                                   placeholder="Dirección"
                                   required="true"/>
                        </div>
                    </div>
                    <div>
                        <p style={{
                            margin: "auto",
                            color: "black",
                            fontSize: "0.8rem",
                            textAlign: "center",
                            padding: "2em",
                            width: "70%"
                        }}>
                            Al seleccionar registrar autoriza que sus datos personales sean tratados conforme a la ley
                            1581 de 2012 acorde a nuestra politica de tratamiento de datos la cual podrá ser consultada
                            en la página web <a style={{color: "var(--secondary-color)"}}>www.farmedicall.com</a>
                        </p>
                    </div>
                </div>
                <div className="submit">
                    <input type="submit" value="Enviar" className="button"/>
                </div>
            </form>
        </div>
    )
}


export default FormulaNueva;