import './marcalaboratorio.css'
import {useEffect, useState} from "react";
import {fetchMarcasLaboratoriosResource} from "../../util/api";

const Marcalaboratorio = () => {
    const [seccionMarcasLaboratorios, setSeccionMarcasLaboratorios] = useState({
        titulo: '',
        subtitulo: '',
        marcas: []
    })

    useEffect(() => {
        fetchMarcasLaboratoriosResource()
            .then(seccion => {
                setSeccionMarcasLaboratorios(seccion);
            })
    }, []);

    return (
        <section className="marcalaboratorio">
            <div className="marcalaboratorio-title">
                <h1>{seccionMarcasLaboratorios.titulo}</h1>
                <p>{seccionMarcasLaboratorios.subtitulo}</p>
            </div>
            <div className="imgs">
                {seccionMarcasLaboratorios.marcas.map(marca => {
                    return <img alt={marca.alt} src={marca.img}/>
                })}
            </div>
        </section>
    )
}

export default Marcalaboratorio;

