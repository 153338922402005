import './nuestralineaservicios.css';


const NuestraLineaServicios = () => {
    const linea = [
    ];
    return (
        <section className='linea-servicios'>
            <div>
                <h1 style={{ fontSize: "2rem" }}>Conoce nuestros servicios</h1>
            </div>
            <div className='servicio'>
                <div
                    style={{
                        margin: "2rem -4rem auto auto",
                        display: "flex",
                        width: "30rem",
                        height: "25rem",
                        zIndex: "99",
                        alignItems: "center"
                    }}>
                    <img src="img/pharmasict-customer.png" width="100%" />
                </div>
                <div>
                    <div style={{
                        width: "100%",
                        overflowX: "hidden",
                        backgroundColor: "rgba(240, 240, 240, 1)",
                        paddingLeft: "5rem",
                        textAlign: "left"
                    }}>
                        <h1 style={{
                            color: "var(--secondary-color)",
                        }}>Dispensación</h1>
                        <h4>Servicio Farmacéutico integral</h4>
                        <p style={{ width: "60%", textAlign: "justify" }}>
                            Nuestro servicio de farmacia ambulatoria se encuentra habilitado para
                            garantizar la entrega de los productos farmacéuticos de alta calidad
                            requeridos por sus afiliados. Como aliados estratégicos de nuestros clientes,
                            hemos desarrollado una plataforma tecnológica que nos permite generar
                            alertas tempranas para la intervención de los Problemas Relacionados con
                            la Utilización de los Medicamentos (PRUM) a través del análisis estadístico
                            de informes históricos de prestación de servicio y consumo. Es importante
                            destacar que a través de la plataforma, se puede hacer un control dirigido
                            al paciente polimedicado, con el fin de asegurar la entrega efectiva de los
                            productos farmacéuticos solicitados por los usuarios, y en consecuencia, hacer
                            eficiente el proceso de dispensación del asegurador.
                            <br></br>
                            <br></br>
                            Nuestro servicio farmacéutico integral es el responsables de las actividades, procedimientos e 
                            intervenciones de carácter técnico, científico y administrativo; dispensación e información sobre el uso adecuado de los medicamentos 
                            y dispositivos médicos utilizados en la promoción de la salud, prevención, diagnóstico, tratamiento y rehabilitación de la enfermedad;
                            con el fin de contribuir en a los resultados en salud y al mejoramiento de la calidad de vida individual y colectiva con mas de 20.000 usuarios atendidos en la ciudad de Neiva.
                        </p>
                    </div>
                </div>
            </div>
            <div>
                <div class="banner"
                    style={{
                        "display": "grid",
                        "grid-template-columns": "1fr",
                        margin: "4rem auto 0 auto !IMPORTANT"
                    }}>
                    <div style={{
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "1.3rem"
                    }}>
                        <p><strong> Actualmente nuestro punto de dispensación se encuentra
                            habilitado en la ciudad de Neiva en la Calle 7 No. 14-68 barrio Altico.</strong></p>
                    </div>
                </div>
            </div>
            <div style={{ fontSize: "3rem", paddingTop: "1rem" }}>|</div>
            <div className='servicio'>
                <div style={{ display: "flex", justifyContent: "end", }}>
                    <div style={{
                        width: "80%",
                        overflowX: "hidden",
                        backgroundColor: "#fff",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        textAlign: "left",
                    }}>
                        <div style={{paddingLeft: "3rem", paddingTop:"1rem"}}>
                            <h1 style={{
                                color: "var(--secondary-color)",
                            }}>Comercialización y Distribución</h1>
                            <h4>Venta directa y al por mayor</h4>
                            <p style={{ width: "80%", textAlign: "justify" }}>
                                Somos importadores exclusivos para Colombia en la línea de hemoderivados del Factor VIII “GREEN VIII”,
                                además de ser distribuidores autorizados en el país de grandes laboratorios farmacéuticos.
                                Nuestro aliado estratégico para el embalaje, transporte y entrega directa de medicamentos con cadena de
                                frío es BIOTHERMICS; empresa Colombiana certficada por el Invima que cuenta con más de 14 años de experiencia
                                en la industria farmacéutica, proporcionando soluciones de embalaje térmico con monitoreo y trazabilidad de la
                                termperatura de los productos transportados, asegurando así, la conservación adecuada según sus especificaciones
                                técnicas.<br></br>
                                <br></br>
                                comercializamos y distribuimos un amplio portafolio de productos farmacéuticos que cubren las principales necesidades de salud de en el ámbito ambulatorio y hospitalario
                                con mas de 2000 moleculas y 300 fabricantes.
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        margin: " auto auto 2rem -4rem",
                        display: "flex",
                        width: "30rem",
                        height: "25rem",
                        zIndex: "1",
                        alignItems: "center",
                    }}>
                    <img src="img/vehiculo-camion.png" width="100%" />
                </div>
            </div>
            <div style={{ fontSize: "3rem", paddingTop: "1rem" }}>|</div>
            <div className='servicio'>
                <div
                    style={{
                        margin: "2rem -4rem auto auto",
                        display: "flex",
                        width: "30rem",
                        height: "25rem",
                        zIndex: "9",
                        alignItems: "center",
                    }}>
                    <img src="img/formulas.png" width="100%" />
                </div>
                <div>
                    <div style={{
                        width: "100%",
                        overflowX: "hidden",
                        backgroundColor: "rgba(240, 240, 240, 1)",
                        paddingLeft: "5rem",
                        textAlign: "left"
                    }}>
                        <h1 style={{
                            color: "var(--secondary-color)",
                        }}>Operación logística</h1>
                        <h4>Logistica y Procesos</h4>
                        <p style={{ width: "80%", textAlign: "justify" }}>
                        Personal capacitado en logística y procesos en todo el sector salud,
                        Prestamos servicios de ALMACENAMIENTO Y ADMINISTRACIÓN DE INVENTARIOS; Por posición de estiba o m3 
                        y Alistamiento de pedidos por referencia y logística para el comercio exterior.
                        </p>
                    </div>
                </div>
            </div>
            <div>
                <div></div>
            </div>
        </section>
    )
}

export default NuestraLineaServicios;