import Footer from "../../components/footer/footer";
import Slider from "../../components/slider/slider";
import Formulamedica from "../../components/formulamedica/formulamedica";

const RadicarOrden = () => {
    window.scrollTo(0, 0);
    return (
        <section className='contenedor-index'>
            <Slider background="/img/paperhospital.jpg">
                <div className="banner"
                    style={{ "display": "grid", "grid-template-columns": "1fr", marginBottom: "-2rem" }}>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "1.3rem",
                    }}>
                        <p><strong>Por favor, suba un archivo para
                            radicar su fórmula médica</strong></p>
                    </div>
                </div>
            </Slider>
            <Formulamedica></Formulamedica>
            <Footer></Footer>
        </section>
    )
}

export default RadicarOrden;