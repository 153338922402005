import Footer from "../../components/footer/footer";
import NuestroProposito from "../../components/nuestroproposito/nuestroproposito";
import Slider from "../../components/slider/slider";
import Nuestrassedes from "../../components/nuestrassedes/nuestrassedes"


function Nosotros() {
    window.scrollTo(0, 0);
    return (
        <section className='contenedor-index'>
            <Slider background="/img/nuestrasSedes.jpg"></Slider>
            <NuestroProposito></NuestroProposito>
            <Nuestrassedes></Nuestrassedes>
            <Footer></Footer>
        </section>
    )
}

export default Nosotros;