import './nuestrassedes.css';

const Nuestrassedes = () => {
    const sedes = [  
    ];
    return (
        <section className="nuestrassedes">
            <div className="nuestrassedes-title">
                <h1>Nuestras sedes</h1>
                <p>
                    Trabajamos en la búsqueda de desarrollos científicos de alta calidad,
                    para ofrecer las mejores soluciones a sus necesidades
                </p>
            </div>
            <section className="nuestrassedes-column">
                <div>
                    <img src="img/FOTO1.png"/>
                </div>
                <div>
                    <img src="img/Foto2.png"/>
                </div>
                <div>
                    <img src="img/FOTO3.png"/>
                </div>
                <div>
                    <img src="img/FOTO4.png"/>
                </div>
           </section>
        </section>
    )
}




export default Nuestrassedes;