import React, { useState } from 'react';

import './slider.css';

const Slider = (props) => {
    const { titulo, subtitulo, children, background } = props;

    return (
        <section className="principal" style={{
            background: "url(" + background + ")",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top left",
        }} >
            <div>
                <h1>{titulo}</h1>
                <p>{subtitulo}</p>
            </div>
            {children}
        </section>
    );
}

export default Slider;