import ContactoForm from '../contactoform/contactoform';
import './contactanos.css'

const Contactanos = () => {
    const contactanos = []

    return (
        <section className="contactanos">
            <div className="contactanos-header">
                <h1>Contáctanos</h1>
                <p>Déjanos tus datos y nos comunicaremos lo más</p>
                <p>pronto posible.</p>
            </div>
            <div className="contactanos-body">  
                <ContactoForm></ContactoForm>
                <div style={{"display": "flex"}}>
                    <img src="/img/mapaHuila.png"/>
                </div>
            </div>
        </section>
    );
}

export default Contactanos;