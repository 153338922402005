import Header from './components/header/header';
import Nosotros from './pages/nosotros/nosotros';
import './App.css';
import Productos from './pages/productos/productos';
import {Route, Routes} from "react-router-dom";
import Index from "./pages/index";
import Servicios from './pages/servicios/servicios';
import Contacto from './pages/contacto/contacto';
import Ingresar from './pages/ingresar/ingresar'
import Radicarorden from  './pages/radicarorden/radicarorden';
import FormulaMedicaNueva from './pages/formulamedicanueva/formulamedicanueva';

function App() {
    return (
        <>
            <Header></Header>
            <Routes>
                <Route path="/" element={<Index />} />
                <Route path="nosotros" element={<Nosotros />} />
                <Route path="productos" element={<Productos />} />
                <Route path="servicios" element={<Servicios />} />
                <Route path="contacto" element={<Contacto />} />
                <Route path="ingresar" element={<Ingresar />} />
                <Route path="radicarorden" element={<Radicarorden />} />
                <Route path="formulamedicanueva" element={<FormulaMedicaNueva />} />                
            </Routes>
        </>
    );
}

export default App;
